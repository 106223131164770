#map-container {
  height: 100%;
  width: 100%;
  /* temporarily set the background color so we can tell where the map container is positioned */
  background-color: lightgrey;
}

.sidebar {
  background-color: rgb(35 55 75 / 90%);
  color: #fff;
  padding: 6px 12px;
  font-family: monospace;
  z-index: 1;
  position: absolute;
  top: 0;
  left: 0;
  margin: 12px;
  border-radius: 4px;
}

.reset-button {
  position: absolute;
  top: 50px;
  z-index: 1;
  left: 12px;
  padding: 4px 10px;
  border-radius: 10px;
  cursor: pointer;
}

.marker {
  display: block;
  border: none;
  cursor: pointer;
  padding: 0;
}

.mapboxgl-popup-content {
  background-color: #101828!important;
  color: white!important;
  padding: 8px!important;
  border-radius: 6px!important;
}
.mapboxgl-popup-tip {
  border-top-color: #101828!important;
  border-bottom-color: #101828!important;
}